import { createSlice } from "@reduxjs/toolkit"

export const initialState = {
  loading: false,
  backdrop: false,
  showProductDetailsDialog: false,
  notifications: [],
}

const uiSlice = createSlice({
  name: "UI",
  initialState,
  reducers: {
    setUiLoading: (state, { payload }) => {
      state.loading = payload
    },
    setUiBackdrop: (state, { payload }) => {
      state.backdrop = payload
    },
    enqueueSnackbar: (state, { payload }) => {
      state.notifications = [
        ...state.notifications,
        {
          key: payload.options.key,
          ...payload,
        },
      ]
    },
    closeSnackbar: (state, { payload }) => {
      state.notifications = state.notifications.map((notification) =>
        payload.dismissAll || notification.key === payload.key
          ? { ...notification, dismissed: true }
          : { ...notification }
      )
    },
    removeSnackbar: (state, { payload }) => {
      state.notifications = state.notifications.filter((notification) => notification.key !== payload)
    },
  },
})

const { setUiLoading, setUiBackdrop, enqueueSnackbar, closeSnackbar, removeSnackbar } = uiSlice.actions
export const uiSelector = (state) => state.ui
export const productDetailsDialogSelector = (state) => state.showProductDetailsDialog

export default uiSlice.reducer

export function enqueueSnackbarAction(notification) {
  return async (dispatch) => {
    dispatch(enqueueSnackbar(notification))
  }
}

export function closeSnackbarAction(notification) {
  return async (dispatch) => {
    dispatch(closeSnackbar(notification))
  }
}

export function removeSnackbarAction(key) {
  return async (dispatch) => {
    dispatch(removeSnackbar(key))
  }
}

export const setUiLoadingAction = (visible = false) => async (dispatch) => {
  dispatch(setUiLoading()(visible))
}

export const setUiBackdropAction = (visible = false) => async (dispatch) => {
  dispatch(setUiBackdrop(visible))
}
